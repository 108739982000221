import React from 'react'
import ServicesCard from './Components/Card'
import Service from './style'
import SectionTitle from './Components/SectionTitle'
import { Col, Container, Row } from 'react-bootstrap'
export default function ServicesSection() {
  return (
    <Service>
      <Container className="container">
        <Row className="row justify-content-center">
          <Col className="col-xl-8 text-center">
            <SectionTitle
              title="Provided Featrues"
              titleProps={{ mb: "40px", mbLG: "75px" }}
              subTitleProps={{ mb: "20px" }}
            />
          </Col>
        </Row>

        <Row className="row justify-content-center">
          {/* Single Services */}
          <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
            <ServicesCard title="Cloud Enablement-Cloud Migration" icon="fas  fa-cloud" iconColor="#8a55df" text="" />
          </Col>
          {/*/ .Single Services */}
          {/* Single Services */}
          <Col className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
            <ServicesCard title="Cloud Security-Provision Management" icon="fas fa-lock" iconColor="#8a55df" text="" />
          </Col>
          {/*/ .Single Services */}
          <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
            <ServicesCard title="Database Administration" icon="fas fa-database" iconColor="#8a55df" text="" />
          </Col>
          {/*/ .Single Services */}
          <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
            <ServicesCard title="Site Reliability Engineering" icon="fas fa-wrench" iconColor="#8a55df" text="" />
          </Col>
          {/*/ .Single Services */}
          {/* <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
            <ServicesCard title="API Reference" icon="fas fa-chart-pie" iconColor="#be79df" text="There are many variations and<br class='d-none d-xs-block d-lg-none d-xl-block'> passages of Lorem
        lpsum<br class='d-none d-xs-block  d-lg-none d-xl-block'> available for use" />
          </Col> */}
          {/*/ .Single Services */}
        </Row>
      </Container>
    </Service>
  )
}

