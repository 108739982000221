import React from "react";
import { PageWrapper } from "~components/Core";


import FooterFive from '~sections/agency/FooterFive'

import ContentOne from '~sections/services/clouds-enablement/Content'

import FeatureSection from '~sections/services/clouds-enablement/Feature'
import ServicesSectionTwo from '~sections/services/clouds-enablement/ServicesTwo'

import ContactSection from "~sections/contact/ContactOne/ContactSection";

import CtaSection from '~sections/it/Cta'

import Services from '~sections/services/clouds-enablement/Services'


import Integration from '~sections/services/clouds-enablement/Integration'

export default function Project() {
    return (
        <PageWrapper innerPage={true}>

            <ContentOne />
            <Services />

            {/* <FeatureSection />
            <ServicesSectionTwo /> */}
            {/* <ContactSection /> */}

            <Integration />


            <CtaSection />


            <FooterFive />

        </PageWrapper>
    )
}
